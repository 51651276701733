import axiosService from '../axios/axiosService'
import jwtDefaultConfig from '../../auth/jwt/jwtDefaultConfig'

class AuthService {
  // jwtConfig <= Will be used by this service
  jwtConfig = { ...jwtDefaultConfig }

  login(...args) {
    return axiosService.axiosIns.post(this.jwtConfig.loginEndpoint, ...args)
  }

  resetPassword(...args) {
    return axiosService.axiosIns.patch(this.jwtConfig.resetPassword, ...args)
  }

  register(...args) {
    return axiosService.axiosIns.post(this.jwtConfig.createUser, ...args)
  }

  verifyAuthyCode(...args) {
    return axiosService.axiosIns.post(this.jwtConfig.verifyAuthyCodeEndpoint, ...args)
  }

  signUp(...args) {
    return axiosService.axiosIns.post(this.jwtConfig.signUp, ...args)
  }

  updateUser(...args) {
    return axiosService.axiosIns.patch(this.jwtConfig.updateUser, ...args)
  }

  deleteUser(...args) {
    return axiosService.axiosIns.post(this.jwtConfig.deleteUser, ...args)
  }

  getUserById(args) {
    return axiosService.axiosIns.get(this.jwtConfig.getUserById, { params: args })
  }

  getUsers(args) {
    return axiosService.axiosIns.get(this.jwtConfig.getUsers, { params: args })
  }
}

function useAuthService() {
  const authService = new AuthService()

  return {
    authService,
  }
}

const { authService } = useAuthService()
export default authService
