<template>
  <b-sidebar
    id="sidebar-add-new-user"
    sidebar-class="sidebar-lg"
    bg-variant="white"
    shadow
    backdrop
    no-header
    no-close-on-backdrop
    right
    @hidden="resetUser"
    @shown="init()"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ user === null ? 'Add' : 'Edit' }} User
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- form -->
      <validation-observer
        v-slot="{ invalid }"
        ref="userValidation"
      >
        <!-- Body -->

        <b-form
          ref="userForm"
          class="p-2"
          @submit.prevent
        >

          <!-- Title -->
          <b-form-group
            label-for="First Name"
            label="First Name"
          >
            <validation-provider
              #default="{ errors }"
              name="First Name"
              vid="firstName"
              rules="required"
            >
              <b-form-input
                id="firstName"
                v-model="user.firstName"
                name="firstName"
                :state="errors.length > 0 ? false:null"
                placeholder="First Name"
                autofocus
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- Last Name -->
          <b-form-group
            label-for="lastName"
            label="Last Name"
          >
            <validation-provider
              #default="{ errors }"
              name="lastName"
              vid="lastName"
              rules="required"
            >
              <b-form-input
                id="lastName"
                v-model="user.lastName"
                name="lastName"
                :state="errors.length > 0 ? false:null"
                placeholder="Last Name"
                autofocus
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- Username -->
          <b-form-group
            label-for="username"
            label="Username"
          >
            <validation-provider
              #default="{ errors }"
              name="username"
              vid="username"
              rules="required"
            >
              <b-form-input
                id="username"
                v-model="user.username"
                name="username"
                :state="errors.length > 0 ? false:null"
                placeholder="Username"
                autofocus
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- Password -->
          <b-form-group
            v-if="requiredPassword"
            label="Password"
            label-for="a-password"
          >
            <validation-provider
              #default="{ errors }"
              name="Password"
              vid="Password"
              rules="required|min:8|password"
            >
              <b-form-input
                id="a-password"
                v-model="user.password"
                :state="errors.length > 0 ? false:null"
                type="password"
                placeholder="Password"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- Password Confirmation -->
          <b-form-group
            v-if="requiredPassword"
            label="Confirm Password"
            label-for="ac-password"
          >
            <validation-provider
              #default="{ errors }"
              name="Confirm Password"
              rules="required|confirmed:Password"
            >

              <b-form-input
                id="ac-password"
                v-model="passValue"
                :state="errors.length > 0 ? false:null"
                type="password"
                placeholder="Confirm Password"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- email -->
          <b-form-group
            label-for="email"
            label="Email"
          >
            <validation-provider
              #default="{ errors }"
              name="email"
              vid="email"
              rules="required"
            >
              <b-form-input
                id="email"
                v-model="user.email"
                name="email"
                :state="errors.length > 0 ? false:null"
                placeholder="email@email.com"
                autofocus
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- phone -->
          <b-form-group
            label="Phone"
            label-for="Phone"
          >
            <validation-provider
              #default="{ errors }"
              name="phone"
              vid="phone"
              rules="required|country-code|phone-number"
            >
              <b-input-group>
                <b-input-group-prepend is-text>
                  <feather-icon
                    icon="PhoneCallIcon"
                    size="12"
                  />
                </b-input-group-prepend>
                <cleave
                  ref="phoneInput"
                  v-model="user.phone"
                  class="form-control"
                  :raw="false"
                  :options="options.phone"
                  placeholder="+1 343 567 8900"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>

            </validation-provider>
          </b-form-group>

          <!-- Project -->
          <b-form-group>
            <validation-provider
              #default="{ errors }"
              name="Profile"
              rules="required"
            >
              <b-form-group
                label="Profile"
                label-for="profile"
                :state="errors.length > 0 ? false:null"
              >
                <v-select
                  id="profile"
                  v-model="user.profile"
                  item-text="Name"
                  item-value="Id"
                  :options="catalogLists.Profile"
                  label="Name"
                />
                <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-form-group>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              :key="buttonKey"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              :disabled="invalid"
              type="submit"
              @click.once="validationForm()"
            >
              {{ user.idUser === 0 ? 'Add' : 'Edit' }}
            </b-button>

            <b-button
              ref="closeSideBarUser"
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BSidebar, BForm, BFormGroup, BFormInput, BButton, BFormInvalidFeedback, BInputGroup, BInputGroupPrepend,
} from 'bootstrap-vue'
import Cleave from 'vue-cleave-component'
// eslint-disable-next-line import/no-extraneous-dependencies

import 'cleave.js/dist/addons/cleave-phone.mx'
import 'cleave.js/src/addons/phone-type-formatter.mx'

import { required, min, confirmed } from '@validations'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import catalogServices from '@core/services/catalog/catalogService'
import authService from '@core/services/auth/authService'
// import catalogServices from '@core/services/catalog/catalogService'
// import ticketsServices from '@core/services/tickets/ticketsService'
// import moment from 'moment'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    vSelect,
    BFormInvalidFeedback,
    ValidationProvider,
    ValidationObserver,
    BInputGroup,
    BInputGroupPrepend,
    Cleave,
  },
  directives: {
    Ripple,
  },
  props: {
    refreshHandler: {
      type: Function,
      required: true,
    },
    idUser: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      user: {
        idUser: 0,
        firstName: '',
        lastName: '',
        password: '',
        username: '',
        phone: '',
        email: '',
        profile: null,
      },
      passValue: '',
      options: {
        phone: {
          phone: true,
          phoneRegionCode: 'MX',
        },
      },
      catalogLists: {
        Profile: [], // most be Array of: { Id: Number, Name: String }
      },
      buttonKey: 1,
      // validation rules
      required,
      min,
      confirmed,
      permissionAdmin: false,
    }
  },
  computed: {
    requiredPassword() {
      return this.idUser < 1
    },
  },
  methods: {
    /* eslint-disable no-param-reassign */
    validationForm() {
      this.$refs.userValidation.validate().then(success => {
        if (success) {
          const objUser = {
            idUser: this.idUser,
            firstName: this.user.firstName,
            lastName: this.user.lastName,
            password: this.user.password,
            username: this.user.username,
            phoneNumber: this.user.phone.replaceAll(' ', ''),
            email: this.user.email,
            idProfile: this.user.profile.Id,
          }
          authService[this.idUser > 0 ? 'updateUser' : 'register'](objUser).then(() => {
            this.$refs.closeSideBarUser.click()
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Success',
                text: `The user has been ${this.idUser > 0 ? 'updated' : 'created'}`,
                icon: 'InboxIcon',
                variant: 'success',
              },
            })
            this.refreshHandler()
            this.resetTicket()
          }).catch(error => {
            this.buttonKey += 1
            this.toastErrorHandler(error)
          })
        }
      })
    },
    init() {
      this.buttonKey += 1
      this.getCatalogs()
      this.loadUser()
    },
    async getCatalogs() {
      const listCatalogs = Object.keys(this.catalogLists)
      listCatalogs.forEach(catalog => {
        this.getSpecificCatalog(catalog)
      })
    },
    async getSpecificCatalog(catalog) {
      return catalogServices.getAllElementCatalog({
        table: catalog,
      }).then(response => {
        const { data } = response.data
        this.catalogLists[catalog] = data
      }).catch(error => {
        this.toastErrorHandler(error)
      })
    },
    loadUser() {
      if (this.idUser === 0) return
      authService.getUserById({ id: this.idUser })
        .then(response => {
          const { data } = response.data
          this.orderUser(data)
        }).catch(error => {
          this.toastErrorHandler(error)
        })
    },
    orderUser(val) {
      if (val === undefined) return

      const {
        idUser,
        firstName,
        lastName,
        username,
        email,
        nameProfile,
        phone,
        idProfile,
      } = val

      this.user = {
        profile: { Id: idProfile, Name: nameProfile },
        idUser,
        firstName,
        phone,
        lastName,
        username,
        email,
      }
    },
    resetUser() {
      this.user = {
        idUser: 0,
        firstName: '',
        lastNAme: '',
        username: '',
        password: '',
        email: '',
        user: '',
        profile: null, // most be: { Id: Number, Name: String }
      }
      this.resetFormUser()
    },
    resetFormUser() {
      this.$refs.userValidation.reset()
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
