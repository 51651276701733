<template>

  <!-- Table Container Card -->
  <b-card
    no-body
  >

    <div class="m-2">

      <!-- Table Top -->
      <b-row>

        <!-- Per Page -->
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <label>Entries</label>
          <v-select
            v-model="perPage"
            :options="PageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block ml-50 mr-1"
          />
          <b-button
            v-if="permission"
            v-b-toggle.sidebar-add-new-user
            variant="primary"
            @click="Create"
          >
            Add User
          </b-button>
        </b-col>

        <!-- Search -->
        <b-col
          cols="12"
          md="6"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input
              v-model="searchTerm"
              class="d-inline-block mr-1"
              placeholder="Search..."
              @input="handleSearch"
            />
            <v-select
              v-model="TypeStaffValue"
              :options="TypeofStaff"
              label="Name"
              input-id="payment-method"
              class="invoice-filter-select"
              placeholder="Select staff"
              :clearable="true"
            />
          </div>
        </b-col>
      </b-row>

    </div>

    <b-table
      ref="tableTickets"
      :items="items"
      :fields="fields"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      responsive
      style="position: relative !important;"
      @sort-changed="onSortChange"
    >
      <!-- Column: AccountNumber -->
      <template #cell(fullname)="data">
        <b-link
          class="font-weight-bold"
        >
          {{ data.value }}
        </b-link>
      </template>

      <!-- Column: Patient -->
      <template #cell(email)="data">
        <b-media vertical-align="center">
          <template #aside>
            <b-avatar
              button
              class="border BAvatarPatientProfiles"
              size="32"
              :text="avatarText(`${data.value}`)"
              @click="navigateToProfileInfo(data.item.IdUser)"
            />
          </template>
          <span class="font-weight-bold d-block text-nowrap">
            {{ `${data.value}` }}
          </span>
          <small class="text-muted" />
        </b-media>
      </template>

      <!-- Column: PhoneNumber -->
      <template #cell(phone)="data">
        <span class="text-nowrap">
          {{ data.value }}
        </span>
      </template>

      <!-- Column: nameProfile -->
      <template #cell(nameProfile)="data">
        <span class="text-nowrap">
          {{ data.value }}
        </span>
      </template>

      <!-- Column: Actions -->
      <template #cell(Actions)="data">
        <div class="text-nowrap">
          <feather-icon
            :id="`invoice-row-${data.item.IdUser}-send-icon`"
            v-b-toggle.sidebar-add-new-user
            icon="EditIcon"
            class="cursor-pointer"
            size="16"
            @click="getDataofUser(data.item.IdUser)"
          />
          <feather-icon
            :id="`invoice-row-${data.item.IdUser}-preview-icon`"
            icon="Trash2Icon"
            size="16"
            class="mx-1"
            @click="DeleteUser(data.item.IdUser)"
          />
        </div>
      </template>

    </b-table>

    <div class="mx-2 mb-2">
      <b-row>

        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">Showing {{ perPage }} of {{ FullCountEntries }} entries</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >

          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            :current-page="currentPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
            @change="handleChangePage"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
    <sidebar-add-new-profile
      :id-user="idUser ? idUser : 0"
      :refresh-handler="getProfiles"
    />
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BPagination, BTable, BLink, BMedia, BAvatar, VBToggle,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { avatarText } from '@core/utils/filter'
import authService from '@core/services/auth/authService'
import Swal from 'sweetalert2'
import SidebarAddNewProfile from './sidebar/SidebarAddNewProfile.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BPagination,
    vSelect,
    BTable,
    BLink,
    BMedia,
    BAvatar,
    SidebarAddNewProfile,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  data() {
    return {
      fields: [
        { key: 'fullname', label: 'Full Name', sortable: true },
        { key: 'email', label: 'Email', sortable: true },
        { key: 'phone', label: 'Phone Number', sortable: true },
        { key: 'nameProfile', label: 'Profile', sortable: true },
        { key: 'Actions', sortable: true },
      ],
      items: [],
      TypeStaffValue: [],
      TypeofStaff: [],
      type: '',
      perPage: 10,
      PageOptions: [10, 25, 50, 100],
      actualpage: 1,
      totalRows: 0,
      currentPage: 1,
      FullCountEntries: 0,
      sortBy: 'Name',
      sortDesc: false,
      sortDirection: 'asc',
      searchTerm: '',
      avatarText,
      idUser: null,
      permission: true,
    }
  },
  watch: {
    perPage() {
      this.getProfiles()
    },
    TypeStaffValue(val) {
      if (val !== null) {
        this.type = val.Id
      } else {
        this.type = ''
      }
      this.getProfiles()
    },
  },
  mounted() {
    this.getAllStaffs()
  },
  created() {
    this.getProfiles()
  },
  methods: {
    Create() {
      this.getDataUser = null
    },
    getDataofUser(id) {
      this.idUser = id
    },
    DeleteUser(id) {
      Swal.fire({
        title: 'Attention',
        text: ' ¿Do you want to delete this profile?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'delete',
      }).then(result => {
        if (result.isConfirmed) {
          authService.deleteUser({
            id,
          }).then(() => {
            this.getProfiles()
          }).catch(error => {
            this.toastErrorHandler(error)
          })
        }
      })
    },
    navigateToProfileInfo(id) {
      this.$store.commit('profiles/setIdProfile', id)
      this.$router.push({ name: 'profile-info' })
    },
    getAllStaffs() {
      this.$store.commit('profiles/setIdProfile', null)
      const profile = this.$store.getters['user-auth/getStaffProfile']
      if (profile === 2) {
        this.fields.pop()
        this.permission = false
      }
    },
    getProfiles() {
      this.idUser = 0
      authService.getUsers({
        page: this.actualpage,
        name: this.searchTerm,
        rows: this.perPage,
        orderBy: this.sortBy,
        sortBy: this.sortDirection,
      }).then(response => {
        const { data } = response.data
        this.items = data
        this.FullCountEntries = !data ? 0 : data[0].full_count
        this.numPages = !data ? 0 : data[0].pages
        this.totalRows = this.FullCountEntries
        this.currentPage = this.actualpage
      })
    },
    handleSearch(searching) {
      this.searchTerm = searching
      this.actualpage = 1
      this.totalRows = 1
      this.getProfiles()
    },
    handleChangePage(page) {
      this.actualpage = page
      this.getProfiles()
    },
    onSortChange(params) {
      this.sortBy = params.sortBy
      this.sortDesc = params.sortDesc
      const direction = params.sortDesc === true ? 'desc' : 'asc'
      this.sortDirection = direction
      this.getProfiles()
    },
  },
}
</script>

<style>
.per-page-selector {
  width: 90px;
}
.vs__selected-options{
    height: 30px !important;
}
</style>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}

.b-avatar .b-avatar-text{
    font-size: 10px !important;
}

</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
